import React from 'react';

import { rhythm } from '../utils/typography';

class Footer extends React.Component {
  render() {
    return (
      <footer
        style={{
          marginTop: rhythm(2.5),
          paddingTop: rhythm(1),
        }}
      >
        <div
          style={{
            marginBottom: rhythm(1),
          }}
        >
          <div style={{ float: 'right' }}>
            <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
              rss
            </a>
          </div>
          <a
            href="https://mobile.twitter.com/johndatserakis"
            target="_blank"
            rel="noopener noreferrer"
          >
            twitter
          </a>{' '}
          &bull;{' '}
          <a
            href="https://github.com/johndatserakis"
            target="_blank"
            rel="noopener noreferrer"
          >
            github
          </a>{' '}
          &bull;{' '}
          <a
            href="https://www.johndatserakis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            personal website
          </a>
        </div>
        <div className="muted">
          <small>
            Built using {' '}
            <a
              href="https://www.gatsbyjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gatsby.
            </a>
            {' '}
            Theme adopted from {' '}
            <a
              href="https://overreacted.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dan Abramov.
            </a>
          </small>
        </div>
      </footer>
    );
  }
}

export default Footer;
